import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImg from "gatsby-background-image"

const BgPattern = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "bg-pattern.png" }) {
        childImageSharp {
          fixed(quality: 90, width: 1920) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImg
      Tag="section"
      fluid={data.desktop.childImageSharp.fixed}
      backgroundColor={`transparent`}
      style={{ backgroundRepeat: "repeat", backgroundSize: "auto" }}
    >
      {children}
    </BackgroundImg>
  )
}

export default BgPattern
